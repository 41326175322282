.image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("https://realinvesting.ca/images/background/10.jpg");
  background-size: cover;
  background-position: center center;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
}

.center-screen {
  display: flex;
  justify-content: center;
}
